const Container = ({ children, visible = true, className = '', title = '' }) => {
  if (!visible) return <></>;
  return (
    <div className={className} title={title}>
      {children}
    </div>
  );
};

export default Container;
